import { FormsFieldPreset, FIELD_COMPONENT_TYPES, FormPlugin, FieldCollectionType, BASE_DESIGN_GROUPS, FieldRenderConfigType } from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class GeneralSignature extends IGeneralField {
  public get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.signature
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_SIGNATURE
  }

  public get collectionFieldType() {
    return FieldCollectionType.IMAGE
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.SIGNATURE_INPUT
  }

  public get role() {
    return FIELDS.ROLE_FIELD_SIGNATURE
  }

  protected get skin() {
    return 'wixui.skins.SignatureInput'
  }

  protected get layout() {
    return {
      width: 304,
      height: 130,
    }
  }

  protected get data(): any {
    return {
      type: 'SignatureInput',
      titleText: this.translate(`preset.signatureTitleText`),
      clearButtonText: this.translate(`preset.signatureClearButtonText`),
    }
  }

  protected get props() {
    return {
      type: 'SignatureInputProperties',
      direction: 'ltr',
      titlePaddingStart: 1,
      titleMarginBottom: 8,
      required: false,
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
        addFieldPanelData: {
          category: DEFAULT_CATEGORIES.signature,
          isPremium: true,
        }
      }
    }
  }

  public get base() {
    return GeneralSignature.prototype
  }

  public get manifest() {
    return fieldManifest({ duplicatable: true })
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['padBackground', 'padBackgroundDisabled'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['padBackgroundError'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['padBackgroundFocus'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['padBackgroundHover'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['padBorderColor'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: [
          'padBorderColorError',
          'padBorderColorDisabled',
        ],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['padBorderColorFocus'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['padBorderColorHover'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES]: ['padBorderWidth'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: ['padBorderWidthError'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: ['padBorderWidthHover'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: ['padBorderWidthFocus'],
        [BASE_DESIGN_GROUPS.INPUT_CORNERS]: ['padBorderRadius'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: [
          'titleColor',
          'titleAsteriskColor',
          'titleColorDisabled',
          'titleAsteriskColorDisabled',
          'clearButtonColorFocus',
        ],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['titleFont', 'titleFontDisabled'],
        [BASE_DESIGN_GROUPS.INPUT_SHADOW]: ['padBoxShadow'],
        [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]: ['signatureColor'],
        [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: [
          'padLineColor',
          'padLineColorHover',
          'padLineColorFocus',
          'padLineColorDisabled',
          'padLineColorError',
        ],
      },
    }
  }

  public get layoutComponentProps() {
    return ['direction', 'titleMarginBottom', 'titlePaddingStart']
  }

  protected get styleProperties() {
    return {
      'properties': {
        'boxShadowToggleOn-padBoxShadow': 'false',
        'clearButtonColor': '#7F808A',
        'clearButtonColorDisabled': '#7F808A',
        'clearButtonFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
        'clearButtonFontDisabled': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
        'padBackground': '#FFFFFF',
        'padBackgroundHover': '#F2FAF9',
        'padBorderColor': '#919191',
        'padBorderColorDisabled': '#DBDBDB',
        'padBorderColorError': '#FF4040',
        'padBorderColorFocus': '#000000',
        'padBorderColorHover': '#5C5C5C',
        'padBorderRadius': '0px 0px 0px 0px',
        'padBorderWidth': '2',
        'padBorderWidthDisabled': '2',
        'padBorderWidthFocus': '2',
        'padBorderWidthHover': '2',
        'padBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'padLineColor': '#000000',
        'padLineColorDisabled': '#CCCCCC',
        'padLineColorFocus': '#000000',
        'padLineColorHover': '#000000',
        'padLineWidth': '1',
        'padLineWidthDisabled': '1',
        'padLineWidthError': '1',
        'padLineWidthFocus': '1',
        'padLineWidthHover': '1',
        'signatureColor': '#000000',
        'signatureSize': '2',
        'titleAsteriskColor': '#7F808A',
        'titleAsteriskColorDisabled': '#CCCCCC',
        'titleColor': '#7F808A',
        'titleColorDisabled': '#CCCCCC',
        'titleFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
        'titleFontDisabled': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
      },
      'propertiesSource': {
        'boxShadowToggleOn-padBoxShadow': 'value',
        'clearButtonColor': 'theme',
        'clearButtonColorDisabled': 'value',
        'clearButtonFont': 'value',
        'clearButtonFontDisabled': 'value',
        'padBackground': 'value',
        'padBackgroundHover': 'value',
        'padBorderColor': 'value',
        'padBorderColorDisabled': 'value',
        'padBorderColorError': 'value',
        'padBorderColorFocus': 'value',
        'padBorderColorHover': 'value',
        'padBorderRadius': 'value',
        'padBorderWidth': 'value',
        'padBorderWidthDisabled': 'value',
        'padBorderWidthFocus': 'value',
        'padBorderWidthHover': 'value',
        'padBoxShadow': 'value',
        'padLineColor': 'value',
        'padLineColorDisabled': 'value',
        'padLineColorFocus': 'value',
        'padLineColorHover': 'value',
        'padLineWidth': 'value',
        'padLineWidthDisabled': 'value',
        'padLineWidthError': 'value',
        'padLineWidthFocus': 'value',
        'padLineWidthHover': 'value',
        'signatureColor': 'value',
        'signatureSize': 'value',
        'titleAsteriskColor': 'value',
        'titleAsteriskColorDisabled': 'value',
        'titleColor': 'value',
        'titleColorDisabled': 'value',
        'titleFont': 'value',
        'titleFontDisabled': 'value',
      },
      'groups': {},
    }
  }
}
